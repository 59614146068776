/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import { Provider } from 'mobx-react'
import { configure as MobXConfig } from 'mobx'
// import globalStore from './stores/globalStore'
import './index.css'
import { history, routingStore } from './utils/history'

// import './components/config/lang/i18n'
import App from './App'

import reportWebVitals from './reportWebVitals'

MobXConfig({
  // cannot change state modifications outside actions
  // enforceActions: 'always',
})

const stores = {
  routingStore,
}

ReactDOM.render(
  // <React.StrictMode>
  <Provider {...stores}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
