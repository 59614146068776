import React from 'react'
import emailjs from 'emailjs-com'

import Twitter from '../../img/snsLogo/twitter.png'
import Facebook from '../../img/snsLogo/facebook.png'
import Youtube from '../../img/snsLogo/youtube.png'
import Telegram from '../../img/snsLogo/telegram.png'
import Kakaoch from '../../img/snsLogo/kakaoch.png'
import Naverblog from '../../img/snsLogo/naverblog.png'
import Instagram from '../../img/snsLogo/instsagram.png'
import Medium from '../../img/snsLogo/medium.png'
import Discord from '../../img/snsLogo/discord.png'

const snslists = [
    {src: Twitter, name: 'twitter', link:'https://twitter.com/grinbit2?s=09'},
    {src: Facebook, name: 'facebook', link:'https://www.facebook.com/GRINBNC/'},
    // {src: Youtube, name: 'youtube', link:'https://youtube.com/channel/UCz9HX9R1ihOqL6h_JOHQjOw'},
    {src: Telegram, name: 'telegram', link:'https://t.me/joinchat/9G0flhz7zyBhOGY1'},
    // {src: Kakaoch, name: 'kakaoch', link:'http://pf.kakao.com/_xnZxjws'},
    {src: Naverblog, name: 'naverblog', link:'https://m.blog.naver.com/grinbit'},
    // {src: Instagram, name: 'instagram', link:'https://www.instagram.com/grinbit/'},
    {src: Medium, name: 'medium', link:'https://medium.com/@grinbit'},
    // {src: Discord, name: 'discord', link:'https://discord.com/invite/PGNyEsBJmj'}
]


function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_780u4tr', 'template_cyqeb9n', e.target, 'user_HVl9XerWh1EuGbpIewtnc')
        .then((result) => {
            // console.log(result.text);
        }, (error) => {
            // console.log(error.text);
        });

    e.target.reset();
}

const ContactUs = () => {
    return (
        <div id="section7" className="contact_cont_w">
            <div className="common_inner">
                <strong className="contact_title">Contact Us</strong>
                <div className="contact_box_form">
                    <form className="contact_form"
                          onSubmit={sendEmail}>
                        <label className="form_section_name" htmlFor="name">Name</label>
                        <input type="text" id="formName" className="ip_text" name="name"/>
                        <label className="form_section_email" htmlFor="email">Email</label>
                        <input type="text" id="formEmail" className="ip_text" name="email"/>
                        <label className="form_section_message" htmlFor="massage">Message</label>
                        <textarea id="formMessage" className="ip_textarea" name="message"></textarea>
                        <button className="submit_btn">
                            <span className="btn_text">Submit</span>
                        </button>
                    </form>
                </div>
        <div className="contact_box_text">
        <div className="contact_info_text">
        <span className="section_sub_title">GRINBNC</span>
        <span className="info_title">Head Office</span>
    <div className="cit_text">
        6 EU TONG SEN STREET #11-10G<br/>
    THE CENTRAL SINGAPORE 059817<br/>
    E : info@grinbit.io
    </div>
    </div>
    <div className="contact_sns_w">
        <span className="info_title">CONNECT</span>
        <ul className="contact_sns_list">
        {snslists.map(item => {
            return (
                <li className="sns_cont">
                    <a href={item.link} className="sns_link">
                        <img src={item.src} alt={item.name}/>
                    </a>
                </li>
            )
        })}
    </ul>
    </div>
    </div>
    </div>

        </div>

    )
}

export default ContactUs