import React, { useState } from 'react'
import '../../publish/css/common.css'
import { useTranslation } from 'react-i18next'
import '../config/lang/i18n'
import $ from 'jquery'
import AppleAppStore from '../../assets/Images/btn_appleAppStore.png'
import GoogleAppStore from '../../assets/Images/btn_googleAppStore.png'

const Navigation = [
  { id: '#section2', name: 'Service' },
  { id: '#section3', name: 'Roadmap' },
  { id: '#section4', name: 'Partners' },
  // { id: '#section5', name: 'Team' },
  { id: '#section6', name: 'News' },
  { id: '#section7', name: 'ContactUs' },
]

const Header = () => {
  const { t, i18n } = useTranslation()
  const [currentLang, setCurrentLang] = useState('ko')

  const handleSwitchKor = () => {
    if (currentLang === 'en') {
      i18n.changeLanguage('ko')
      setCurrentLang('ko')
      $('#handleSwitchEng').removeClass('current')
      $('#handleSwitchKor').addClass('current')
    }
  }

  const handleSwitchEng = () => {
    if (currentLang === 'ko') {
      i18n.changeLanguage('en')
      setCurrentLang('en')
      $('#handleSwitchKor').removeClass('current')
      $('#handleSwitchEng').addClass('current')
    }
  }

  // Banner : Grinbit Wallet App Download link
  let appleAppStoreLink = ''

  if (currentLang === 'ko') {
    appleAppStoreLink = 'https://apps.apple.com/kr/app/id1615326236'
  } else {
    appleAppStoreLink = 'https://apps.apple.com/app/id1615326236'
  }

  return (
    <nav id="nav" className="grinbit_lnb_w">
      <div className="common_inner">
        <h1 className="grinbit_h1_logo">
          <a href="#section1" className="logolink_main">
            <span className="icon_logo_gnb">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="156.003"
                height="38"
                viewBox="0 0 156.003 38"
              >
                <g transform="translate(-733.398 -204.551)">
                  <path
                    className="grinbit_logo_svg"
                    d="M761.654,226.688h-8.2a.134.134,0,0,0-.127.084.136.136,0,0,0,.029.153l4.126,4.211a3.581,3.581,0,0,0,2.544,1.069h3.35a.556.556,0,0,0,.558-.534,6.933,6.933,0,0,0-1.78-4.757A.663.663,0,0,0,761.654,226.688Z"
                  />
                  <path
                    className="grinbit_logo_svg"
                    d="M751.343,226.688h-8.2a.663.663,0,0,0-.5.226,6.934,6.934,0,0,0-1.78,4.757.555.555,0,0,0,.558.534h3.35a3.581,3.581,0,0,0,2.544-1.069l4.126-4.211a.135.135,0,0,0,.029-.152A.133.133,0,0,0,751.343,226.688Z"
                  />
                  <path
                    className="grinbit_logo_svg"
                    d="M752.4,204.551a19,19,0,1,0,19,19A19,19,0,0,0,752.4,204.551Zm0,3.565A1.887,1.887,0,1,1,750.512,210,1.889,1.889,0,0,1,752.4,208.116ZM742.071,223l.174-.641a10.511,10.511,0,0,1,8.649-7.646,1.01,1.01,0,0,0,.839-.773l.334-1.412a.341.341,0,0,1,.663,0l.334,1.413a1.009,1.009,0,0,0,.839.772,10.511,10.511,0,0,1,8.649,7.646l.174.641a.435.435,0,0,1-.42.549h-7.043a.434.434,0,0,1-.427-.357l-.186-1.015a.435.435,0,0,1,.428-.513h5.236a8.641,8.641,0,0,0-15.831,0h6.083a1.211,1.211,0,0,0,1.161-.88l.263-.939a.357.357,0,0,1,.351-.267.361.361,0,0,1,.36.3l.567,3.158a.434.434,0,0,1-.428.512H742.491a.435.435,0,0,1-.42-.549Zm23.523,10.367a.937.937,0,0,1-.916.726h-4.653a5.479,5.479,0,0,1-3.891-1.635l-3.635-3.711a.142.142,0,0,0-.1-.042.14.14,0,0,0-.1.042l-3.636,3.711a5.475,5.475,0,0,1-3.89,1.635H740.12a.94.94,0,0,1-.918-.726,8.8,8.8,0,0,1,2.651-8.331.942.942,0,0,1,.618-.23h19.856a.943.943,0,0,1,.617.23A8.806,8.806,0,0,1,765.594,233.364Z"
                  />
                  <g transform="translate(0.347)">
                    <path
                      className="grinbit_logo_svg"
                      d="M863.166,218.685a3.822,3.822,0,0,0-3.818-3.817H848.642a4.6,4.6,0,0,0-4.592,4.592v8.092a4.6,4.6,0,0,0,4.592,4.592h10.706a3.822,3.822,0,0,0,3.818-3.817v-3.462a4.381,4.381,0,0,0-1.409-3.22A3.812,3.812,0,0,0,863.166,218.685Zm-2.028,9.642a1.792,1.792,0,0,1-1.79,1.79H848.642a2.568,2.568,0,0,1-2.565-2.565V222.5h12.7a2.365,2.365,0,0,1,2.362,2.362Zm-1.79-7.852H846.077V219.46a2.568,2.568,0,0,1,2.565-2.564h10.706a1.79,1.79,0,1,1,0,3.579Z"
                    />
                    <path
                      className="grinbit_logo_svg"
                      d="M785.049,230.117a6.611,6.611,0,0,1,0-13.221h9.464a1.014,1.014,0,0,0,0-2.028h-9.464a8.638,8.638,0,0,0,0,17.276h9.464a1.013,1.013,0,0,0,1.014-1.013v-9.642a1.014,1.014,0,0,0-1.014-1.014h-13.27a1.014,1.014,0,0,0,0,2.028H793.5v7.614Z"
                    />
                    <path
                      className="grinbit_logo_svg"
                      d="M811.971,214.868h-6.308a8.648,8.648,0,0,0-8.638,8.638v7.625a1.013,1.013,0,0,0,2.027,0v-7.625a6.635,6.635,0,0,1,.076-1h7l8.242,9.271a1.014,1.014,0,0,0,1.516-1.346L808.84,222.5h6.287a1.014,1.014,0,0,0,1.014-1.014v-2.451A4.175,4.175,0,0,0,811.971,214.868Zm2.142,5.607H799.789a6.619,6.619,0,0,1,5.874-3.579h6.308a2.145,2.145,0,0,1,2.142,2.14`2Z"
                    />
                    <path
                      className="grinbit_logo_svg"
                      d="M819.516,214.868a1.014,1.014,0,0,0-1.014,1.014v15.249a1.013,1.013,0,0,0,2.027,0V215.882A1.013,1.013,0,0,0,819.516,214.868Z"
                    />
                    <path
                      className="grinbit_logo_svg"
                      d="M841.275,215.046a1.014,1.014,0,0,0-1.014,1.013v12.826l-15.4-13.586a1.013,1.013,0,0,0-1.684.76v15.072a1.014,1.014,0,0,0,2.027,0V218.3l15.4,13.586a1.013,1.013,0,0,0,1.684-.76V216.059A1.013,1.013,0,0,0,841.275,215.046Z"
                    />
                    <path
                      className="grinbit_logo_svg"
                      d="M888.04,214.957H870.952a1.013,1.013,0,1,0,0,2.027h7.573V231.22a1.013,1.013,0,0,0,2.027,0V216.984h7.488a1.013,1.013,0,1,0,0-2.027Z"
                    />
                    <path
                      className="grinbit_logo_svg"
                      d="M866.412,214.957a1.013,1.013,0,0,0-1.013,1.014V231.22a1.014,1.014,0,0,0,2.027,0V215.971A1.014,1.014,0,0,0,866.412,214.957Z"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </a>
        </h1>
        <h2 className="grinbit_allmenu_w">
          <button type="button" className="btn_allmenu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="25.999"
              viewBox="0 0 32 25.999"
            >
              <defs>
                <style>{`.allmenu{fill:url(#a);}`}</style>
                <linearGradient
                  id="a"
                  x2="1"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="#e83290" />
                  <stop offset="1" stopColor="#c11dcc" />
                </linearGradient>
              </defs>
              <path
                className="allmenu"
                d="M-1990,22V20h32v2Zm0-12V8h32v2Zm0-12V-4h32v2Z"
                transform="translate(1990 4)"
              />
            </svg>
          </button>
        </h2>
        <div className="grinbit_lnb_inner">
          <strong className="grinbit_h1_logo">
            <a href="#none" className="logolink_main">
              <span className="icon_logo_gnb">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="156.003"
                  height="38"
                  viewBox="0 0 156.003 38"
                >
                  <g transform="translate(-733.398 -204.551)">
                    <path
                      className="grinbit_logo_svg"
                      d="M761.654,226.688h-8.2a.134.134,0,0,0-.127.084.136.136,0,0,0,.029.153l4.126,4.211a3.581,3.581,0,0,0,2.544,1.069h3.35a.556.556,0,0,0,.558-.534,6.933,6.933,0,0,0-1.78-4.757A.663.663,0,0,0,761.654,226.688Z"
                    />
                    <path
                      className="grinbit_logo_svg"
                      d="M751.343,226.688h-8.2a.663.663,0,0,0-.5.226,6.934,6.934,0,0,0-1.78,4.757.555.555,0,0,0,.558.534h3.35a3.581,3.581,0,0,0,2.544-1.069l4.126-4.211a.135.135,0,0,0,.029-.152A.133.133,0,0,0,751.343,226.688Z"
                    />
                    <path
                      className="grinbit_logo_svg"
                      d="M752.4,204.551a19,19,0,1,0,19,19A19,19,0,0,0,752.4,204.551Zm0,3.565A1.887,1.887,0,1,1,750.512,210,1.889,1.889,0,0,1,752.4,208.116ZM742.071,223l.174-.641a10.511,10.511,0,0,1,8.649-7.646,1.01,1.01,0,0,0,.839-.773l.334-1.412a.341.341,0,0,1,.663,0l.334,1.413a1.009,1.009,0,0,0,.839.772,10.511,10.511,0,0,1,8.649,7.646l.174.641a.435.435,0,0,1-.42.549h-7.043a.434.434,0,0,1-.427-.357l-.186-1.015a.435.435,0,0,1,.428-.513h5.236a8.641,8.641,0,0,0-15.831,0h6.083a1.211,1.211,0,0,0,1.161-.88l.263-.939a.357.357,0,0,1,.351-.267.361.361,0,0,1,.36.3l.567,3.158a.434.434,0,0,1-.428.512H742.491a.435.435,0,0,1-.42-.549Zm23.523,10.367a.937.937,0,0,1-.916.726h-4.653a5.479,5.479,0,0,1-3.891-1.635l-3.635-3.711a.142.142,0,0,0-.1-.042.14.14,0,0,0-.1.042l-3.636,3.711a5.475,5.475,0,0,1-3.89,1.635H740.12a.94.94,0,0,1-.918-.726,8.8,8.8,0,0,1,2.651-8.331.942.942,0,0,1,.618-.23h19.856a.943.943,0,0,1,.617.23A8.806,8.806,0,0,1,765.594,233.364Z"
                    />
                    <g transform="translate(0.347)">
                      <path
                        className="grinbit_logo_svg"
                        d="M863.166,218.685a3.822,3.822,0,0,0-3.818-3.817H848.642a4.6,4.6,0,0,0-4.592,4.592v8.092a4.6,4.6,0,0,0,4.592,4.592h10.706a3.822,3.822,0,0,0,3.818-3.817v-3.462a4.381,4.381,0,0,0-1.409-3.22A3.812,3.812,0,0,0,863.166,218.685Zm-2.028,9.642a1.792,1.792,0,0,1-1.79,1.79H848.642a2.568,2.568,0,0,1-2.565-2.565V222.5h12.7a2.365,2.365,0,0,1,2.362,2.362Zm-1.79-7.852H846.077V219.46a2.568,2.568,0,0,1,2.565-2.564h10.706a1.79,1.79,0,1,1,0,3.579Z"
                      />
                      <path
                        className="grinbit_logo_svg"
                        d="M785.049,230.117a6.611,6.611,0,0,1,0-13.221h9.464a1.014,1.014,0,0,0,0-2.028h-9.464a8.638,8.638,0,0,0,0,17.276h9.464a1.013,1.013,0,0,0,1.014-1.013v-9.642a1.014,1.014,0,0,0-1.014-1.014h-13.27a1.014,1.014,0,0,0,0,2.028H793.5v7.614Z"
                      />
                      <path
                        className="grinbit_logo_svg"
                        d="M811.971,214.868h-6.308a8.648,8.648,0,0,0-8.638,8.638v7.625a1.013,1.013,0,0,0,2.027,0v-7.625a6.635,6.635,0,0,1,.076-1h7l8.242,9.271a1.014,1.014,0,0,0,1.516-1.346L808.84,222.5h6.287a1.014,1.014,0,0,0,1.014-1.014v-2.451A4.175,4.175,0,0,0,811.971,214.868Zm2.142,5.607H799.789a6.619,6.619,0,0,1,5.874-3.579h6.308a2.145,2.145,0,0,1,2.142,2.142Z"
                      />
                      <path
                        className="grinbit_logo_svg"
                        d="M819.516,214.868a1.014,1.014,0,0,0-1.014,1.014v15.249a1.013,1.013,0,0,0,2.027,0V215.882A1.013,1.013,0,0,0,819.516,214.868Z"
                      />
                      <path
                        className="grinbit_logo_svg"
                        d="M841.275,215.046a1.014,1.014,0,0,0-1.014,1.013v12.826l-15.4-13.586a1.013,1.013,0,0,0-1.684.76v15.072a1.014,1.014,0,0,0,2.027,0V218.3l15.4,13.586a1.013,1.013,0,0,0,1.684-.76V216.059A1.013,1.013,0,0,0,841.275,215.046Z"
                      />
                      <path
                        className="grinbit_logo_svg"
                        d="M888.04,214.957H870.952a1.013,1.013,0,1,0,0,2.027h7.573V231.22a1.013,1.013,0,0,0,2.027,0V216.984h7.488a1.013,1.013,0,1,0,0-2.027Z"
                      />
                      <path
                        className="grinbit_logo_svg"
                        d="M866.412,214.957a1.013,1.013,0,0,0-1.013,1.014V231.22a1.014,1.014,0,0,0,2.027,0V215.971A1.014,1.014,0,0,0,866.412,214.957Z"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            </a>
          </strong>
          <ul className="grinbit_lnb">
            <li className="slnb_inner active">
              <a href="#section1" className="slnb_link">
                <span className="slnb_txt_box">
                  <span className="slnb_txt">Download</span>
                </span>
              </a>
            </li>
            {Navigation.map((item) => {
              return (
                <li className="slnb_inner">
                  <a href={item.id} className="slnb_link">
                    <span className="slnb_txt_box">
                      <span className="slnb_txt">{item.name}</span>
                    </span>
                  </a>
                </li>
              )
            })}
          </ul>
          <button type="button" className="btn_allmenu_x">
            <span className="icon_close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.999"
                height="25.999"
                viewBox="0 0 25.999 25.999"
              >
                <defs>
                  <style>{`.close_x{fill:url(#a);}`}</style>
                  <linearGradient
                    id="a"
                    x1="0.966"
                    y1="0.966"
                    x2="0.034"
                    y2="0.041"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0" stopColor="#c11dcc" />
                    <stop offset="1" stopColor="#e83290" />
                  </linearGradient>
                </defs>
                <path
                  className="close_x"
                  d="M-1977.051-722.144l-11.085,11.042-1.864-1.857L-1978.915-724-1990-735.043l1.865-1.857,11.085,11.042L-1965.865-737l1.864,1.858L-1975.186-724-1964-712.859l-1.864,1.858Z"
                  transform="translate(1990 737)"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Header
