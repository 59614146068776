import React, { useEffect } from 'react'

import "./publish/css/common.css"
import $ from "jquery"
import "jquery-easing"

import Header from './components/layouts/Header'
import Content from './components/layouts/Content'

const App = () => {

    useEffect(() => {
        function listAnimation() {
            const wScroll = document.body.scrollTop || document.documentElement.scrollTop;
            // const ourteam = $(".ourteam_list_w").offset().top;
            // const advisor = $(".ouradvisor_list_w").offset().top;

            // if(ourteam - 900 < wScroll){
            //     $('.ourteam_list_w .module_inner').each(function () {
            //         const $this = $(this);
            //         $this.addClass('current');
            //     });
            // }

            // if(advisor - 900 < wScroll){
            //     $('.ouradvisor_list_w .module_inner').each(function () {
            //         const $this = $(this);
            //         $this.addClass('current');
            //     });
            // }

        }

        function gnbSticky(){
            $(window).scroll(function () {
                const scrollHeight = $(document).scrollTop();
                if (scrollHeight > 158) {
                    $('.common_gnb_w').addClass('sticky');
                } else if (scrollHeight < 157) {
                    $('.common_gnb_w').removeClass('sticky');
                }
            });
        }

        function mobileGnb(){
            $('.btn_allmenu').click( function() {
                $('.grinbit_lnb_w').addClass('gnb_open');
            } );
            $('.btn_allmenu_x').click( function() {
                $('.grinbit_lnb_w').removeClass('gnb_open');
            } );
            $('.slnb_link').click( function() {
                $('.grinbit_lnb_w').removeClass('gnb_open');
            } );
        }

        const mql = window.matchMedia("screen and (max-width: 768px)");
        if (mql.matches) {
            // console.log("화면의 너비가 720px 보다 작습니다.");
            mobileGnb();

        } else {
            // console.log("화면의 너비가 720px 보다 큽니다.");
            gnbSticky();
        }

        const nav = $("#nav ul li");
        const cont = $("#contents > div");

        nav.click(function(e){
            e.preventDefault();
            const target = $(this);
            const index = target.index();
            const section = cont.eq(index);
            const offset = section.offset().top;
            $("html,body").animate({ scrollTop:offset },600,"easeInOutExpo");
        });

        $(window).scroll(function(){
            const wScroll = $(this).scrollTop();
            listAnimation();

            // if(wScroll >= cont.eq(0).offset().top -6 ){
            //     nav.removeClass("active");
            //     nav.eq(0).addClass("active");
            //     $('.grinbit_lnb_w').removeClass('fixed');
            // }
            // if(wScroll >= cont.eq(1).offset().top -6 ){
            //     nav.removeClass("active");
            //     nav.eq(1).addClass("active");
            //     $('.grinbit_lnb_w').addClass('fixed');
            //     // $('.download_app').detach();
            // }
            // if(wScroll >= cont.eq(2).offset().top -6 ){
            //     nav.removeClass("active");
            //     nav.eq(2).addClass("active");
            // }
            // if(wScroll >= cont.eq(3).offset().top -6 ){
            //     nav.removeClass("active");
            //     nav.eq(3).addClass("active");
            // }
            // if(wScroll >= cont.eq(4).offset().top -6 ){
            //     nav.removeClass("active");
            //     nav.eq(4).addClass("active");
            // }
            // if(wScroll >= cont.eq(5).offset().top -6 ){
            //     nav.removeClass("active");
            //     nav.eq(5).addClass("active");
            // }
            // if(wScroll >= cont.eq(6).offset().top -6 ){
            //     nav.removeClass("active");
            //     nav.eq(6).addClass("active");
            // }
        });

        $(window).resize(function() {   });

    }, [])

    return(
      <div className="wrap">
          <Header/>
          <Content/>
      </div>
    )
}

export default App;