import React from 'react'

const Roadmap = () => {
  return (
      <div id="section3" className="roadmap_cont_w">
        <strong className="section_title">Roadmap</strong>
        <div id="timeline" className="roadmap_list_w">
          <div className="timelineContainer">
            <div className="timelineElements">
              <div className="timelineGroup">
                <div className="timelineYear">
                  <strong className="yearText">2021</strong>
                </div>
                <div className="timelineNum_w">
                  <div className="timelineNum">
                    <p className="numberText">Q1</p>
                    <ul className="timelineCont_list_w">
                      <li className="tcl_text">GRINBIT Development</li>
                    </ul>
                  </div>
                  <div className="timelineNum">
                    <p className="numberText">Q2</p>
                    <ul className="timelineCont_list_w">
                      <li className="tcl_text">Platform Architecture</li>
                      <li className="tcl_text">Build Online Community</li>
                      <li className="tcl_text">Release WhitePaper Ver 1.0</li>
                    </ul>
                  </div>
                  <div className="timelineNum">
                    <p className="numberText">Q3</p>
                    <ul className="timelineCont_list_w">
                      <li className="tcl_text">Point Service Open</li>
                      <li className="tcl_text">Web Dashboard Open</li>
                      <li className="tcl_text">Initial Coin Offering</li>
                    </ul>
                  </div>
                  <div className="timelineNum">
                    <p className="numberText">Q4</p>
                    <ul className="timelineCont_list_w">
                      <li className="tcl_text">Platform Prototype</li>
                      <li className="tcl_text">Relase WhitePaper Ver 2.0</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="timelineGroup group2022">
                <div className="timelineYear">
                  <strong className="yearText">2022</strong>
                </div>
                <div className="timelineNum_w">
                  <div className="timelineNum">
                    <p className="numberText">Q1</p>
                    <ul className="timelineCont_list_w">
                      <li className="tcl_text">Exchange Listing</li>
                      <li className="tcl_text">Close-Beta Open</li>
                    </ul>
                  </div>
                  <div className="timelineNum">
                    <p className="numberText">Q2</p>
                    <ul className="timelineCont_list_w">
                      <li className="tcl_text">Open-Beta Open</li>
                      <li className="tcl_text">Reward Service Launch</li>
                    </ul>
                  </div>
                  <div className="timelineNum">
                    <p className="numberText">Q3</p>
                    <ul className="timelineCont_list_w">
                      <li className="tcl_text">Platform Open</li>
                      <li className="tcl_text">Point Swap API Open</li>
                      <li className="tcl_text">APP Launch</li>
                    </ul>
                  </div>
                  <div className="timelineNum">
                    <p className="numberText">Q4</p>
                    <ul className="timelineCont_list_w">
                      <li className="tcl_text">API For Service</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="timelineGroup group2023">
                <div className="timelineYear">
                  <strong className="yearText">2023</strong>
                </div>
                <div className="timelineNum_w">
                  <div className="timelineNum">
                    <p className="numberText">Q1</p>
                    <ul className="timelineCont_list_w">
                      <li className="tcl_text">Build Partnerships</li>
                      <li className="tcl_text">Advertising Activities</li>
                    </ul>
                  </div>
              </div>
            </div>
            </div>


          </div>
        </div>
      </div>
  )
}

export default Roadmap