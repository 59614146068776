import React, { useEffect } from 'react'
import $ from "jquery"
import { useTranslation } from "react-i18next";

import '../../publish/css/common.css'
import '../../publish/css/slick.css'
import '../../publish/js/slick-1.6.0.min'

import ServiceImg1 from '../../assets/ServiceImages/service_01.png'
import ServiceImg2 from '../../assets/ServiceImages/service_02.png'
import ServiceImg3 from '../../assets/ServiceImages/service_03.png'
import ServiceImg4 from '../../assets/ServiceImages/service_04.png'
import ServiceImg5 from '../../assets/ServiceImages/service_05.png'
import ServiceImg6 from '../../assets/ServiceImages/service_06.png'
import ServiceImg7 from '../../assets/ServiceImages/service_07.png'


const services = [
    // { src:ServiceImg1, title:"그린비 화상 통화" , description: "service1"},
    { src:ServiceImg1, title:"화상 통화" , description: "service1"},
    { src:ServiceImg2, title:"교육" , description: "service2"},
    { src:ServiceImg3, title:"서포터즈 상담" , description: "service3"},
    { src:ServiceImg4, title:"라이브 스트리밍 방송" , description: "service4"},
    { src:ServiceImg5, title:"GAME" , description: "service5"},
    { src:ServiceImg6, title:"SNS" , description: "service6"},
    { src:ServiceImg7, title:"Entertainment" , description: "service7"}
];

const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36']

const Service = () => {
    const { t } = useTranslation();

    useEffect(() => {

        function serviceSlick(){
            $('.md_middle_list').slick({
                centerMode: true,
                centerPadding: '60px',
                slidesToShow: 3,
                prevArrow: $('#aro1_prev'),
                nextArrow: $('#aro1_next'),
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            centerPadding: '40px',
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            centerPadding: '40px',
                            slidesToShow: 1
                        }
                    }
                ]
            });
        }

        serviceSlick();
    },[])

    return (
        <div id="section2" className="service_cont_w">
            <strong className="section_title">Service</strong>
            <div className="service_list_w">
                <div className="arrowSlider">
                    <button type="button" id="aro1_next" className="arrowBtn_next">
                        <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86" className="arrow_svg_next">
                            <defs>
                                <style>{`
                                    .nextArrow_a{fill:url(#nextArrow_a);}
                                    .nextArrow_b,.nextArrow_c{fill:none;stroke:#fff;stroke-width:2px;}
                                    .nextArrow_b{stroke-linejoin:round;}
                                    .nextArrow_d{filter:url(#nextArrow_b);}
                                `}
                                </style>
                                <linearGradient id="nextArrow_a" x1="0.168" y1="0.124" x2="0.865" y2="0.85" gradientUnits="objectBoundingBox">
                                    <stop offset="0" stopColor="#e83290"/>
                                    <stop offset="1" stopColor="#c11dcc"/>
                                </linearGradient>
                                <filter id="nextArrow_b" x="0" y="0" width="86" height="86" filterUnits="userSpaceOnUse">
                                    {/* <feOffset input="SourceAlpha"/> */}
                                    <feGaussianBlur stdDeviation="5" result="c"/>
                                    <feFlood floodColor="#99366b"/>
                                    <feComposite operator="in" in2="c"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <g transform="translate(-1328 -1502)">
                                <g className="nextArrow_d" transform="matrix(1, 0, 0, 1, 1328, 1502)">
                                    <path className="nextArrow_a" d="M28,56A28.007,28.007,0,0,1,17.1,2.2,28.007,28.007,0,0,1,38.9,53.8,27.824,27.824,0,0,1,28,56ZM28,2a26.007,26.007,0,0,0-10.12,49.957A26.007,26.007,0,0,0,38.12,4.043,25.838,25.838,0,0,0,28,2Z" transform="translate(15 15)"/>
                                </g>
                                <g transform="translate(1352.5 1526.5)">
                                    <path className="nextArrow_b" d="M7.5,18h21"/>
                                    <path className="nextArrow_c" d="M18,7.5,28.5,18,18,28.5"/>
                                </g>
                            </g>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86" className="arrow_svg_next arrow_hover">
                            <defs>
                                <style>{`
                                    .nextArrow_a2{fill:url(#nextArrow_a2);}
                                    .nextArrow_b2,.nextArrow_c2{fill:none;stroke:#fff;stroke-width:2px;}
                                    .nextArrow_b2{stroke-linejoin:round;}
                                    .nextArrow_d2{filter:url(#nextArrow_b2);}
                                `}
                                </style>
                                <linearGradient id="nextArrow_a2" x1="0.168" y1="0.124" x2="0.865" y2="0.85" gradientUnits="objectBoundingBox">
                                    <stop offset="0" stopColor="#e83290"/>
                                    <stop offset="1" stopColor="#c11dcc"/>
                                </linearGradient>
                                <filter id="nextArrow_b2" x="0" y="0" width="86" height="86" filterUnits="userSpaceOnUse">
                                    {/* <feOffset input="SourceAlpha"/> */}
                                    <feGaussianBlur stdDeviation="5" result="c"/>
                                    <feFlood floodColor="#99366b"/>
                                    <feComposite operator="in" in2="c"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <g transform="translate(-1328 -1502)">
                                <g className="nextArrow_d2" transform="matrix(1, 0, 0, 1, 1328, 1502)">
                                    <circle className="nextArrow_a2" cx="28" cy="28" r="28" transform="translate(15 15)"/>
                                </g>
                                <g transform="translate(1352.5 1526.5)">
                                    <path className="nextArrow_b2" d="M7.5,18h21"/>
                                    <path className="nextArrow_c2" d="M18,7.5,28.5,18,18,28.5"/>
                                </g>
                            </g>
                        </svg>
                    </button>
                    <button type="button" id="aro1_prev" className="arrowBtn_prev">
                        <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86" className="arrow_svg_prev">
                            <defs>
                                <style>{`
                                    .prevArrow_a{fill:url(#prevArrow_a);}
                                    .prevArrow_b,.prevArrow_c{fill:none;stroke:#fff;stroke-width:2px;}
                                    .prevArrow_b{stroke-linejoin:round;}
                                    .prevArrow_d{filter:url(#prevArrow_b);}
                                `}
                                </style>
                                <linearGradient id="prevArrow_a" x1="0.168" y1="0.124" x2="0.865" y2="0.85" gradientUnits="objectBoundingBox">
                                    <stop offset="0" stopColor="#e83290"/>
                                    <stop offset="1" stopColor="#c11dcc"/>
                                </linearGradient>
                                <filter id="prevArrow_b" x="0" y="0" width="86" height="86" filterUnits="userSpaceOnUse">
                                    {/* <feOffset input="SourceAlpha"/> */}
                                    <feGaussianBlur stdDeviation="5" result="c"/>
                                    <feFlood floodColor="#99366b"/>
                                    <feComposite operator="in" in2="c"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <g transform="translate(15 15)">
                                <g className="prevArrow_d" transform="matrix(1, 0, 0, 1, -15, -15)">
                                    <path className="prevArrow_a" d="M28,56A28.007,28.007,0,0,1,17.1,2.2,28.007,28.007,0,0,1,38.9,53.8,27.824,27.824,0,0,1,28,56ZM28,2a26.007,26.007,0,0,0-10.12,49.957A26.007,26.007,0,0,0,38.12,4.043,25.838,25.838,0,0,0,28,2Z" transform="translate(15 15)"/>
                                </g>
                                <g transform="translate(18 17)">
                                    <path className="prevArrow_b" d="M28.5,18H7.5" transform="translate(-7.5 -7.5)"/>
                                    <path className="prevArrow_c" d="M28.5,7.5,18,18,28.5,28.5" transform="translate(-18 -7.5)"/>
                                </g>
                            </g>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86" className="arrow_svg_prev arrow_hover">
                            <defs>
                                <style>{`
                                    .prevArrow_a2{fill:url(#prevArrow_a2);}
                                    .prevArrow_b2,.prevArrow_c2{fill:none;stroke:#fff;stroke-width:2px;}
                                    .prevArrow_b2{stroke-linejoin:round;}
                                    .prevArrow_d2{filter:url(#prevArrow_b2);}
                                `}
                                </style>
                                <linearGradient id="prevArrow_a2" x1="0.168" y1="0.124" x2="0.865" y2="0.85" gradientUnits="objectBoundingBox">
                                    <stop offset="0" stopColor="#e83290"/>
                                    <stop offset="1" stopColor="#c11dcc"/>
                                </linearGradient>
                                <filter id="prevArrow_b2" x="0" y="0" width="86" height="86" filterUnits="userSpaceOnUse">
                                    {/* <feOffset input="SourceAlpha"/> */}
                                    <feGaussianBlur stdDeviation="5" result="c"/>
                                    <feFlood floodColor="#99366b"/>
                                    <feComposite operator="in" in2="c"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <g transform="translate(15 15)">
                                <g className="prevArrow_d2" transform="matrix(1, 0, 0, 1, -15, -15)">
                                    <circle className="prevArrow_a2" cx="28" cy="28" r="28" transform="translate(15 15)"/>
                                </g>
                                <g transform="translate(18 17)">
                                    <path className="prevArrow_b2" d="M28.5,18H7.5" transform="translate(-7.5 -7.5)"/>
                                    <path className="prevArrow_c2" d="M28.5,7.5,18,18,28.5,28.5" transform="translate(-18 -7.5)"/>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
                <ul className="md_middle_list">
                    {services.map(item => {
                        return(
                            <li className="md_middle_inner">
                                <div className="md_mdl_cont">
                                    <div className="service_icon_w">
                                        <div className="service_icon">
                                            <img src={item.src} alt={item.title}/>
                                        </div>
                                    </div>
                                    <strong className="md_mdl_title">{t(item.title)}</strong>
                                    <p className="md_mdl_text">{t(item.description)}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <i className="service_bg"></i>
            <div className="circle_container_w">
                {
                    numbers.map((item) => {
                        return(
                            <div className="circle-container">
                                <div className="circle"></div>
                            </div>
                        )
                    })
                }
            </div>
        </div>

    )
}

export default Service