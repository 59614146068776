import React from 'react'

import Banner from '../content/Banner'
import Service from '../content/Service'
import Roadmap from '../content/Roadmap'
import Partners from '../content/Partners'
import Team from '../content/Team'
import News from '../content/News'
import ContactUs from '../content/ContactUs'
import Notics from '../content/Notics'

const Content = () => {
    return(
        <div id="contents">
            <Banner/>
            <Service/>
            <Roadmap/>
            <Partners/>
            {/* <Team/> */}
            <News/>
            <ContactUs/>
            {/* <Notics /> */}
        </div>

    )
}

export default Content;