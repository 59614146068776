import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import NewsImage1 from '../../assets/NewsImages/Grinbit_news.jpeg'
import NewsImage2 from '../../assets/NewsImages/Grinbi_klaytn.png'
import NewsImage3 from '../../assets/NewsImages/LBank_Grinbit.png'
import NewsImage4 from '../../assets/NewsImages/LBank_news.png'
import NewsImage5 from '../../assets/NewsImages/DiscoveryNews.jpeg'
import NewsImage6 from '../../assets/NewsImages/news_digifinex_listing.png'



const news = [
    {
        src: 'https://www.tokenpost.kr/article-86452',
        url: NewsImage1,
        title: '그린비 "군인과 사회 잇는 통합 플랫폼 구축한다." - TOKENPOST',
        description: '군인을 위한 서비스는 대부분 군 복무를 하는 특정 기간에만 활용 가능하도록 제공되어 왔다.'
    },
    {
      src: 'https://coinness.live/news/1022194',
      url: NewsImage2,
      title: '그린비, 클레이튼 생태계 합류 - CoinNess',
      description: '군 장병 대상 전문 서비스와 쇼핑몰을 제공하는 그린비가 1분기 서비스 전면 개편과 사업영역...'
    },
    {
        src: 'https://support.lbank.site/hc/en-gb/articles/4952614570265-GRBT-GRINIBIT-will-be-listed-on-LBank',
        url: NewsImage3,
        title: 'GRBT(GRINBIT)will be listed on LBank',
        description: 'GRBT(GRINBIT)will be listed on LBank at 18:00 on March 22, 2022 (UTC+8)'
    },
    {
        src: 'https://www.coinreaders.com/26602',
        url: NewsImage4,
        title: '그린비티(GRBT), 싱가폴 거래소 엘뱅크 상장',
        description: '군(軍)과 민간을 연결하는 통합 플랫폼 그린비티 프로젝트인 ‘그린비티’가 싱가폴 소재 글로벌 거래소 엘뱅크(LBank)에 첫 상장'
    },
    {
        src: 'http://www.discoverynews.kr/news/articleView.html?idxno=763619',
        url: NewsImage5,
        title: '그린비티, 싱가폴 엘뱅크 상장',
        description: '군인과 사회를 잇는 통합 플랫폼 그린비티 프로젝트인 ‘그린비티’가 싱가폴 소재 글로벌 거래소 엘뱅크(LBank)에 첫 상장'
    },
    {
        src: 'https://digifinex.zendesk.com/hc/en-us/articles/5692294504217',
        url: NewsImage6,
        title: '[New Coin Listing] GRINBIT (GRBT) to Be Listed on DigiFinex',
        description: 'DigiFinex will list GRINBIT (GRBT) at 15:00(GMT+8), April 14th, 2022.'
    },
]

const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1028,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        // {
        //     breakpoint: 1280,
        //     settings: {
        //         slidesToShow: 2,
        //         slidesToScroll: 1,
        //         initialSlide: 2
        //     }
        // },
        {
            breakpoint: 720,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

const News = () => {
  return(
      <div id="section6" className="news_cont_w">
          <strong className="section_title">News</strong>
          <div className="common_inner">
              <div className="news_list_w">
                  <ul className="module_list">
      <Slider {...settings}>
          {news.map(item => {
              return (
                  <li className="module_inner">
                      <a href={item.src}  className="module_cont">
                          <div className="module_box_w">
                              <div className="mb_inner">
                                  <div className="mb_image_w">
                                      <div className="mb_image_inner">
                                          <img className="mb_image" src={item.url} alt="news" />
                                      </div>
                                  </div>
                                  <div className="mb_text_w">
                                      <div className="mb_title">{item.title}</div>
                                      <div className="mb_subtext">{item.description}</div>
                                  </div>
                              </div>
                          </div>
                      </a>
                  </li>
              )
          })}
      </Slider>
                  </ul>
              </div>
          </div>
      </div>
  )
}

export default News
