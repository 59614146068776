import React from 'react'
import '../../publish/css/partners.css'

import LG from '../../img/companyLogo/logo_lgu.png'
import sejong from '../../img/companyLogo/logo_sejong.png'
import KDDI from '../../img/companyLogo/logo_kddi.png'
import Military from '../../img/companyLogo/logo_military.png'
import Army from '../../img/companyLogo/logo_army.png'
import Airforce from '../../img/companyLogo/logo_airforce.png'
import Navy from '../../img/companyLogo/logo_navy.png'
import Marine from '../../img/companyLogo/logo_marine.png'

import Hyundai from '../../img/companyLogo/logo_hyundai.png'
import Payjoa from '../../img/companyLogo/logo_payjoa.png'
import Bizmarket from '../../img/companyLogo/logo_bizmarket.png'
import HelloVision from '../../img/companyLogo/logo_hellovision.png'
import Mezzomedia from '../../img/companyLogo/logo_mezzomedia.png'
import Seerslab from '../../img/companyLogo/logo_seerslab.png'

import Klaytn from '../../img/companyLogo/logo_klaytn.png'
// import Bitasset from '../../img/companyLogo/logo_bitasset.png'
import Bitasset from '../../img/companyLogo/logo_bitasset_new.png'
import GrinbiPartners from '../../img/companyLogo/logo_grinbi_partners.png'
import GrinbitKorea from '../../img/companyLogo/logo_grinbit_korea.png'
// import Ethereum from '../../img/companyLogo/logo_ethereum.jpeg'
import Richi from '../../img/companyLogo/logo_richi.png'
import One4U from '../../img/companyLogo/logo_one4u.png'
import GMCK from '../../img/companyLogo/logo_gmck.png'
import BlockPatch from '../../img/companyLogo/logo_blockpatch.png'
import NinePartners from '../../img/companyLogo/logo_9partners.png'
import PruneBombSoft from '../../img/companyLogo/logo_prunebombsoft.png'
import CashtreeAD from '../../img/companyLogo/logo_cashtreead.png'
import LBank from '../../img/companyLogo/logo_lbank.png'
import DigiFinex from '../../img/companyLogo/logo_digifinex.png'
import Certik from '../../img/companyLogo/certik.jpg'

// import { ReactComponent as Certik } from '../../assets/Images/CompanyLogo/certik-logotype-h-w.svg'

const partners = [
  {
    name: 'Grinbit_korea',
    src: GrinbitKorea,
    logo: 'partners_inner logo_dongseo',
  },
  {
    name: 'Grinbi_partners',
    src: GrinbiPartners,
    logo: 'partners_inner logo_skt',
  },
  { name: 'Klaytn', src: Klaytn, logo: 'partners_inner logo_lgu' },
  { name: 'Bitasset', src: Bitasset, logo: 'partners_inner logo_sejong' },
  // { name: 'Ethereum', src:Ethereum, logo: 'partners_inner logo_kddi'},
  { name: 'Rich', src: Richi, logo: 'partners_inner logo_hyundai' },
  { name: 'GMCK', src: GMCK, logo: 'partners_inner logo_army' },
  {
    name: 'BlockPatch',
    src: BlockPatch,
    logo: 'partners_inner logo_blockpatch',
  },
  {
    name: '9Partners',
    src: NinePartners,
    logo: 'partners_inner logo_ninepartners',
  },
  {
    name: 'PruneBombSoft',
    src: PruneBombSoft,
    logo: 'partners_inner logo_army',
  },
  {
    name: 'CashtreeAD',
    src: CashtreeAD,
    logo: 'partners_inner logo_military',
  },
  { name: 'LBank', src: LBank, logo: 'partners_inner logo_navy' },
  { name: 'DigiFinex', src: DigiFinex, logo: 'partners_inner logo_airforce' },
  { name: 'Certkit', src: Certik, logo: 'partners_inner logo_kddi' },
]

const gribiPartners = [
  { name: 'Military', src: Military, logo: 'partners_inner logo_military' },
  { name: 'Army', src: Army, logo: 'partners_inner logo_army' },
  { name: 'Airforce', src: Airforce, logo: 'partners_inner logo_airforce' },
  { name: 'Navy', src: Navy, logo: 'partners_inner logo_navy' },
  { name: 'Marine', src: Marine, logo: 'partners_inner logo_marine' },
  { name: 'One4U', src: One4U, logo: 'partners_inner logo_hanabank' },
  { name: 'LG', src: LG, logo: 'partners_inner logo_lgu' },
  { name: 'sejong', src: sejong, logo: 'partners_inner logo_sejong' },
  { name: 'KDDI', src: KDDI, logo: 'partners_inner logo_kddi' },
  { name: 'Hyundai', src: Hyundai, logo: 'partners_inner logo_hyundai' },
  { name: 'Payjoa', src: Payjoa, logo: 'partners_inner logo_payjoa' },
  { name: 'Bizmarket', src: Bizmarket, logo: 'partners_inner logo_bizmarket' },
  {
    name: 'HelloVision',
    src: HelloVision,
    logo: 'partners_inner logo_hellovision',
  },
  {
    name: 'Mezzomedia',
    src: Mezzomedia,
    logo: 'partners_inner logo_mezzomedia',
  },
  { name: 'Seerslab', src: Seerslab, logo: 'partners_inner logo_seerslab' },
]

const numbers = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
]

const Partners = () => {
  return (
    <div id="section4" className="partners_cont_w">
      <strong className="section_title">Partners & Exchanges</strong>
      <div className="common_inner">
        <div className="partners_list_w">
          <ul className="partners_list">
            {partners.map((item) => {
              return (
                <li className={item.logo}>
                  <a href="#none" className="partners_cont">
                    <img src={item.src} alt={item.name} />
                  </a>
                </li>
              )
            })}
          </ul>
          <br />
        </div>
      </div>
      {/* <span className="section_title_sub">GRINBIT Partners</span>
      <div className="common_inner">
        <div className="partners_list_w">
          <div className="grinbiPartners_list">
            <ul className="partners_list">
              {gribiPartners.map((item) => {
                return (
                  <li className={item.logo}>
                    <a href="#none" className="partners_cont">
                      <img src={item.src} alt={item.name} />
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="circle_container_w">
        {numbers.map((item) => {
          return (
            <div className="circle-container">
              <div className="circle"></div>
            </div>
          )
        })}
      </div> */}
    </div>
  )
}

export default Partners
