import React from 'react'

import Video from '../../publish/videos/grinbit_video.mp4'
import ScrollDownIconImg from '../../publish/img/icon_scroll_down.png'
import PosterImg from '../../assets/Images/videoBackgroundImg.PNG'
import WhiteKor from '../../publish/img/white_kor.pdf'
import WhiteEng from '../../publish/img/white_eng.pdf'

const Banner = () => {
    return(
        <div id="section1" className="download_cont_w">
            <video className="visual_video" autoPlay loop muted playsInline preload="auto" poster={PosterImg}>
                <source src={Video} type="video/mp4" />
            </video>
            <div className="visual_text_w">
                <p className="visual_title">Beyond communication, <br/>Do it grinbit</p>
                <div className="download_btn_w">
	    	    <a href={WhiteKor} download="Grinbit_WhitePaper_Kor">
                    <button type="button" className="down_btn" onClick>
                        <span className="down_text">한글 백서 다운로드</span>
                        <span className="icon_down">
                            <svg width="16" height="16" viewBox="0 0 16 16"><path d="M22,14l-1.41-1.41L15,18.17V6H13V18.17L7.42,12.58,6,14l8,8Z" transform="translate(-6 -6)"/></svg>
                        </span>
                    </button>
	    	    </a>
	    	    <a href={WhiteEng} download="Grinbit_WhitePaper_Eng">
                    <button type="button" className="down_btn_eng">
                        <span className="down_text">White Paper Download</span>
                        <span className="icon_down">
                            <svg width="16" height="16" viewBox="0 0 16 16"><path d="M22,14l-1.41-1.41L15,18.17V6H13V18.17L7.42,12.58,6,14l8,8Z" transform="translate(-6 -6)"/></svg>
                        </span>
                    </button>
	    	    </a>
                </div>
            </div>
            <div className="section_arrow_down">
                <svg data-negative="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z" />
                </svg>
                <img alt="arrow" src={ScrollDownIconImg} />
            </div>
        </div>
    )
}

export default Banner;
